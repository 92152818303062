import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_2 = {
  class: "position-fixed top-0 start-0 w-100 h-100 bg-black opacity-90 d-flex flex-column align-items-center justify-content-center",
  style: {"z-index":"9999"}
}
const _hoisted_3 = { class: "text-white" }

import { ref } from 'vue';
import { RouterView } from 'vue-router';
import { useAppOptionStore } from '@/apps/auth/stores/app-option';
import { useAppVariableStore, generateVariables } from '@/shared/stores/app-variable';
import { ProgressFinisher, useProgress } from '@marcoschulte/vue3-progress';
import router from './router';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const appVariable = useAppVariableStore();
const appOption = useAppOptionStore();
const progresses = [] as ProgressFinisher[];
var isLoading = ref(false);

router.beforeEach(async (to, from) => {
	isLoading.value = true;
	progresses.push(useProgress().start());
})
router.afterEach(async (to, from) => {
	isLoading.value = false;
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	progresses.pop()?.finish();
})

function changeColorSheme(newColorScheme) {
	appOption.appMode = newColorScheme;
	document.documentElement.setAttribute('data-bs-theme', newColorScheme);
	var newVariables = generateVariables();
	appVariable.font = newVariables.font;
	appVariable.color = newVariables.color;
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
	const newColorScheme = event.matches ? "dark" : "light";
	changeColorSheme(newColorScheme);
});

if(window.matchMedia('(prefers-color-scheme: dark)').matches) {
	changeColorSheme("dark");
}

document.querySelector('body').classList.add('app-init');

return (_ctx: any,_cache: any) => {
  const _component_vue3_progress_bar = _resolveComponent("vue3-progress-bar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app", {
		'app-header-menu-search-toggled': _unref(appOption).appHeaderSearchToggled,
		'app-content-full-height': _unref(appOption).appContentFullHeight,
		'app-content-full-width': true,
		'app-without-header': _unref(appOption).appHeaderHide,
		'app-boxed-layout': _unref(appOption).appBoxedLayout,
		'app-footer-fixed': _unref(appOption).appFooterFixed,
	}])
  }, [
    _createVNode(_component_vue3_progress_bar),
    (_unref(isLoading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("div", {
              class: "spinner-border mb-2 text-white",
              style: {"width":"3rem","height":"3rem"},
              role: "status"
            }, [
              _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
            ], -1)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("APP.OTHER.WAIT")) + "...", 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["app-content", _unref(appOption).appContentClass])
    }, [
      _createVNode(_unref(RouterView))
    ], 2)
  ], 2))
}
}

})